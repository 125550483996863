import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Page from "./page"
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')



class tabla extends Component {

    render() {
        return (
            <Page
                
            />
        );
    }
}

const mapStateToProps = state => {
    return{

    }
}

const mapDispatchToProps = {
 
}


export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(tabla)
);